import "./App.css";

import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import AppRoutes from "./components/app-toolbar/app-routes/AppRoutes";
import AuthProvider from "./contexts/AuthContexts";
import BatchForm from "./components/batch-form/BatchForm";
import BatchManager from "./components/batch-manager/BatchManager";
import CourseForm from "./components/course-form/CourseForm";
import CourseManager from "./components/course-manager/CourseManager";
import Dashboard from "./components/dashboard/Dashboard";
import EnrollmentForm from "./components/enrollment-form/EnrollmentForm";
import EnrollmentManager from "./components/enrollment-manager/EnrollmentManager";
import MainAppBar from "./components/app-toolbar/MainAppBar";
import PageNotFound from "./components/page-not-found/PageNotFound";
import PrivateRoute from "./components/custom/private-route/PrivateRoute";
import Profile from "./components/profile/Profile";
import ProfileProvider from "./contexts/ProfileContexts";
import SessionForm from "./components/session-form/SessionForm";
import SessionManager from "./components/session-manager/SessionManager";
import SignInForm from "./components/sign-in/SignInForm";
import TeacherForm from "./components/teacher-form/TeacherForm";
import TeacherManager from "./components/teacher-manager/TeacherManager";
import drawerOptions from "./data/DrawerOptions";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#634539",
    },
    secondary: {
      main: "#78797A",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

function App() {
  return (
    <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="App">
              <MainAppBar drawerOptions={drawerOptions}>
                  <AppRoutes />
              </MainAppBar>
            </div>
          </Router>
        </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
