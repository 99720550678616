import './SessionManager.css'

import { Add, Delete, Edit } from '@mui/icons-material';
import { Alert, Button, Chip, CircularProgress, Divider, IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import AlertDialog from '../custom/alert-dialog/AlertDialog';
import { db } from '../../firebase';

function SessionManager() {
const navigate = useNavigate();
const [sessions, setSessions] = useState([]);
const [loading, setLoading] = useState(true);
const [searchParams, setSearchParams] = useSearchParams();
const [actionParams, setActionsParams] = useState(null);
const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
const [deletableSession, setDeletableSession] = useState({id: null, sessionNumber: null});

const [msg, setMsg] = useState("");
const [open, setOpen] = useState(false);
const [severity, setSeverity] = useState("success");

useEffect(() => {
    db.collection("sessions").orderBy("timestamp", "asc").onSnapshot((snapshot) => {
        setSessions(
            snapshot.docs.map((doc) => (
                {
                    id: doc.id,
                    data: doc.data()
                }
            ))
        );
        setLoading(false);
    })

    if(searchParams.get("status") && searchParams.get('sno')){
        setActionsParams({status: searchParams.get('status'), sno: searchParams.get('sno')});
        searchParams.delete("status");
        searchParams.delete("sno");
        setSearchParams(searchParams);
        console.log("search params cleared")
    }
}, [])

const handleUpdate = () => {

}

const handleDelete = (id, sessionNumber) => {
    setDeletableSession({id, sessionNumber});
    setOpenDeleteConfirmation(true);
}

const handleConfirmDialog = () => {
    setOpenDeleteConfirmation(false);
    db.collection("sessions")
      .doc(deletableSession.id)
      .delete()
      .then((success) => {
        console.log("Session deleted successfully !");
        setMsg("Session deleted successfully !");
        setOpen(true);
        setSeverity("success");
      })
      .catch((error) => {
        console.log("Failed to delete Session !");
        setMsg("Failed to delete Session !");
        setOpen(true);
        setSeverity("error");
      });
}

  return (
    <div className="sessionManager">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <div className="sessionManager__header">
        <h1>Manage Sessions</h1>
        <Button
          className="sessionManager__header__btn"
          startIcon={<Add />}
          variant="contained"
          onClick={() => navigate("/session/add")}
          disableElevation
        >
          Session
        </Button>
      </div>

      <Divider />
      {actionParams?.status === "success" && actionParams?.sno && (
        <Alert variant="outlined" severity="success">
          {actionParams.sno + " session created successfully"}
        </Alert>
      )}

      {actionParams?.status === "failure" && actionParams?.sno && (
        <Alert variant="outlined" severity="error">
          {"Failed to create session " + actionParams.sno}
        </Alert>
      )}
      <p>
        In this section you can manage the sessions for Mangal Vidya Peeth
      </p>

      {loading && <CircularProgress size={20} />}

      {!loading && sessions.length > 0 && (
        <div className="sessionManager__tableContainer">
          <TableContainer>
            <Table area-label="sessions-table" size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Session Number</TableCell>
                  <TableCell align="center">Start Year</TableCell>
                  <TableCell align="center">Start Month</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.map(
                  ({
                    id,
                    data: { sessionNumber, startYear, startMonth, isActive },
                  }) => (
                    <TableRow
                      key={id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{sessionNumber}</TableCell>
                      <TableCell align="center">{startYear}</TableCell>
                      <TableCell align="center">{startMonth}</TableCell>
                      <TableCell align="center">
                        {isActive ? <Chip label="Active" size="small" color="success"/> : <Chip label="Inactive" size="small" color="error"/>}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => navigate(`/session/edit/${id}`)}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(id, sessionNumber)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {!loading && sessions.length == 0 && (
        <Alert variant="outlined" severity="info">
          No sessions found!
        </Alert>
      )}

      <AlertDialog
        title="Confirmation"
        description={`Are you sure you want to delete this session number - ${deletableSession.sessionNumber}?`}
        openDialog={openDeleteConfirmation}
        btn1="Cancel"
        btn2="Delete"
        handleCloseDialog={() => setOpenDeleteConfirmation(false)}
        handleConfirmDialog={handleConfirmDialog}
      />
    </div>
  );
}

export default SessionManager
