import "./MainAppBar.css";

import * as React from "react";

import { Avatar, Button } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import AppDrawer from "./app-drawer/AppDrawer";
import { Apps } from "@mui/icons-material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Footer from "../footer/Footer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ProfileProvider from "../../contexts/ProfileContexts";
import PropTypes from "prop-types";
import SocialMedia from "../social-media-handels/SocialMedia";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../../mvp_logo_only.png";
import logoText from "../../mvp_logo_text.png";
import { useAuth } from "../../contexts/AuthContexts";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function MainAppBar(props) {
  const navigate = useNavigate();
  const { window, drawerOptions } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // currentUser
  const { currentUser, logout } = useAuth();

  const handleLogOut = async () => {
    handleClose();
    await logout();
    navigate("/signin");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            // width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white",
            zIndex: "99999",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {currentUser && (
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
                className="mainAppBar__menuIcon"
              >
                <MenuIcon />
              </IconButton>
            )}

            <div className="mainAppBar__logo__section">
              <Box
                component="img"
                className="mainAppBar__logo"
                src={logo}
                alt=""
                sx={{ display: { xs: "none", sm: "block" } }}
                onClick={() => navigate("/")}
              />
              <Box
                component="img"
                className="mainAppBar__logo"
                src={logoText}
                alt=""
                sx={{ height: { xs: "48px", sm: "60px" } }}
                onClick={() => navigate("/")}
              />
              {/* <Typography
                variant="h4"
                noWrap
                component="div"
                className="mainAppBar__heading"
                onClick={() => navigate("/")}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                | मंगल विद्या पीठ
              </Typography> */}
            </div>

            {currentUser && (
              <>
                <Button
                  variant="text"
                  sx={{
                    width: { xs: "26px", sm: "82px" },
                    height: { xs: "26px", sm: "30px" },
                    cursor: "pointer",
                  }}
                  onClick={handleLogOut}
                >
                  Logout
                </Button>

                {/* <Avatar
                  sx={{
                    width: { xs: "26px", sm: "30px" },
                    height: { xs: "26px", sm: "30px" },
                    cursor: "pointer",
                  }}
                  onClick={handleMenu}
                ></Avatar> */}

                {/* <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ zIndex: "100000" }}
                >
                  <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                </Menu> */}
              </>
            )}
          </Toolbar>
        </AppBar>
        {!currentUser && (
          <Box className="mainAppBar__no__current_user">{props.children}</Box>
        )}
        {currentUser && (
          <>
            <ProfileProvider>
              <AppDrawer
                drawerWidth={drawerWidth}
                container={container}
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
              />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
              >
                <Toolbar />
                <div style={{minHeight: "80vh"}}>
                  {props.children}
                </div>
                <Footer />
              </Box>{" "}
            </ProfileProvider>
          </>
        )}
      </Box>
  );
}

MainAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainAppBar;
