import React from 'react';
import './PageNotFound.css'

function PageNotFound() {
  return (
    <div className="pageNotFound">
      <h1>Sorry!</h1>
      <h2>This page does not exists!</h2>
      <span>Click <a href="/">here</a> to go home</span>
    </div>
  );
}

export default PageNotFound