import "./TeacherForm.css";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCities, getCountries, getStates } from "../../helpers/geographics";
import { useNavigate, useParams } from "react-router-dom";

import BoxStack from "../custom/box-stack/BoxStack";
import Container from "../custom/container/Container";
import MenuItem from "@mui/material/MenuItem";
import { db } from "../../firebase.js";
import firebase from "firebase/compat/app";

function TeacherForm() {
  // form variables
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [townOrVillage, setTownOrVillage] = useState("");

  // dropdowns
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // search params
  const { id } = useParams();

  // for navigation
  const navigate = useNavigate();

  // for edit/create mode
  const [editMode, setEditMode] = useState(false);

  // for snackbar notification
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [level, setLevel] = useState("info");

  // loading
  const [loading, setLoading] = useState(true);

  // phone number validation
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);

  useEffect(() => {
    loadCountries();
    loadExistingTeacher();
  }, []);

  const loadCountries = async () => {
    if (!id) {
      setCountry("India");
      const stateList = await getStates("India");
      setStates(stateList);
    }
    const response = await getCountries();
    setCountries([...response]);
  };

  const loadExistingTeacher = async () => {
    if (id) {
      console.log("id = ", id);
      const teacherSnapshot = await db.collection("teachers").doc(id).get();

      if (teacherSnapshot.empty || teacherSnapshot.data() == undefined) {
        setLevel("error");
        setMsg("This course does not exists");
        setOpen(true);
        setLoading(false);
      } else {
        setEditMode(true);
        const data = teacherSnapshot.data();
        setName(data.name);
        setPhoneNumber(data.phoneNumber);
        validatePhoneForE164(data.phoneNumber);
        const stateList = await getStates(data.country);
        setStates(stateList);
        const citiesList = await getCities(data.state);
        setCities(citiesList);
        setCountry(data.country);
        setState(data.state);
        setCity(data.city);
        setTownOrVillage(data.townOrVillage);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  // create a teacher
  const createTeacher = () => {
    if (!editMode) {
      const teacher = {
        name,
        phoneNumber,
        country,
        state,
        city,
        townOrVillage,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      db.collection("teachers")
        .add(teacher)
        .then((success) => {
          setMsg("Teacher created successfully!");
          setOpen(true);
          setLevel("success");
          setTimeout(() => {
            navigate("/teachers");
          }, 500);
        })
        .catch((error) => {
          setMsg("Failed to create the teacher");
          setOpen(true);
          setLevel("error");
          setTimeout(() => {
            navigate("/teachers");
          }, 500);
        });
    } else {
      db.collection("teachers")
        .doc(id)
        .set(
          {
            name,
            phoneNumber,
            country,
            state,
            city,
            townOrVillage,
            updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then((success) => {
          setMsg("Teacher updated successfully!");
          setOpen(true);
          setLevel("success");
          setTimeout(() => {
            navigate("/teachers");
          }, 500);
        })
        .catch((error) => {
          setMsg("Failed to update the teacher");
          setOpen(true);
          setLevel("error");
          setTimeout(() => {
            navigate("/teachers");
          }, 500);
        });
    }
  };

  const handleCountrySelect = async (selectedCountry) => {
    const response = await getStates(selectedCountry);
    setStates([...response]);
    setCountry(selectedCountry);
    const getCurrentCountryCode = countries.find(
      (c) => c.country_name === selectedCountry
    ).country_phone_code;
    setPhoneNumber("+" + getCurrentCountryCode);
  };

  const handleStateSelect = async (selectedState) => {
    const response = await getCities(selectedState);
    setCities([...response]);
    setState(selectedState);
  };

  const validatePhoneForE164 = (phone_number_entered) => {
    console.log(`Validating phone number = ${phone_number_entered}`);
    const regEx = /^\+[1-9]\d{10,14}$/;
    setIsPhoneNumberInvalid(!regEx.test(phone_number_entered));
  };

  if (loading) {
    return (
      <div className="teacherForm" style={{ height: "100vh" }}>
        <CircularProgress size={20} /> Loading
      </div>
    );
  }

  return (
    <Container
      heading={(!editMode ? "Create " : "Update ") + "Teacher"}
      align="center"
      enableHeaderButton={false}
      helperText="Here you can create a teacher for Mangal Vidya Peeth.??"
      loading={false}
      handleCancel={() => navigate("/teachers")}
      handleSubmit={createTeacher}
      submitButtonText={!editMode ? "Create Teacher" : "Update"}
      disableWhen={
        (!name || !country || !state || !phoneNumber || isPhoneNumberInvalid) &&
        (!city || !townOrVillage)
      }
    >
      <BoxStack>
        <BoxStack
          boxWidth="25ch"
          stackDirection="column"
          boxStyle={{ flexDirection: "row"}}
        >
          <TextField
            fullWidth
            type="text"
            id="name"
            margin="normal"
            label="Teacher Name"
            placeholder="Ex. Vaibhav"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {/* Country */}
          <TextField
            id="country"
            select
            label="Country"
            value={country}
            onChange={(e) => handleCountrySelect(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {countries.map((option) => (
              <MenuItem
                key={option.country_short_name}
                value={option.country_name}
              >
                {option.country_name}
              </MenuItem>
            ))}
          </TextField>

          {/* City */}
          <TextField
            id="city"
            select
            label="City"
            value={city}
            disabled={!state}
            onChange={(e) => setCity(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {cities.map((option) => (
              <MenuItem key={option.city_name} value={option.city_name}>
                {option.city_name}
              </MenuItem>
            ))}
          </TextField>
        </BoxStack>
        <BoxStack
          boxWidth="25ch"
          stackDirection="column"
          boxStyle={{ flexDirection: "row" }}
        >
          <TextField
            error={isPhoneNumberInvalid}
            helperText={
              isPhoneNumberInvalid
                ? "Format - [+][country code][phone number]"
                : ""
            }
            fullWidth
            type="text"
            id="phoneNumber"
            margin="normal"
            label="Phone Number"
            placeholder="Ex. +917999523197"
            variant="outlined"
            value={phoneNumber}
            onBlur={(e) => validatePhoneForE164(e.target.value)}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          {/* State */}
          <TextField
            id="state"
            select
            label="State"
            value={state}
            disabled={!country}
            onChange={(e) => handleStateSelect(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {states.map((option) => (
              <MenuItem key={option.state_name} value={option.state_name}>
                {option.state_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            type="text"
            id="townOrVillage"
            margin="normal"
            label="Town/Village (Optional)"
            placeholder="Ex. Amayan"
            variant="outlined"
            value={townOrVillage}
            onChange={(e) => setTownOrVillage(e.target.value)}
          />
        </BoxStack>
      </BoxStack>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert
          severity={level}
          sx={{ width: "100%" }}
          onClose={() => setOpen(false)}
          variant="filled"
        >
          {msg}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default TeacherForm;
