import "./SessionForm.css";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BoxStack from "../custom/box-stack/BoxStack";
import { CheckCircleOutline } from "@mui/icons-material";
import Container from "../custom/container/Container";
import MenuItem from "@mui/material/MenuItem";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import { id } from "date-fns/locale";

const monthMap = new Map([
  [1, "January"],
  [2, "February"],
  [3, "March"],
  [4, "Arpil"],
  [5, "May"],
  [6, "June"],
  [7, "July"],
  [8, "August"],
  [9, "September"],
  [10, "October"],
  [11, "November"],
  [12, "December"],
]);

// //loading
// const [loading, setLoading] = useState(true);

function SessionForm() {
  const navigate = useNavigate();
  const [sessionNumber, setSessionNumber] = useState("");
  const [isValidSessionNumber, setIsValidSessionNumber] = useState(true);
  const [startYear, setStartYear] = useState(new Date().getFullYear() + "");
  const [startMonth, setStartMonth] = useState(monthMap.get(1));
  const [years, setYears] = useState([
    { key: new Date().getFullYear(), value: new Date().getFullYear() },
  ]);
  const [months, setMonths] = useState([{ key: 1, value: monthMap.get(1) }]);
  const [isActive, setIsActive] = useState(false);

  // delete session feature
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);

  // snackbar
  const [open, setOpen] = useState(false);
  const [sev, setSev] = useState("info");
  const [msg, setMsg] = useState("");

  const [
    checkExistingActiveSession = {
      id: null,
      data: null,
    },
    setExistingActiveSession,
  ] = useState();

  useEffect(() => {
    getExistingActiveSession();
    populateYears();
    populateMonths();
    populateExistingSession();
  }, []);

  const getExistingActiveSession = async () => {
    db.collection("sessions")
      .where("isActive", "==", true)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setExistingActiveSession({
            id: doc.id,
            data: doc.data(),
          });
        });
      })
      .catch(function (error) {
        console.log("Error getting active sessions: ", error);
      });
  };

  const populateExistingSession = async () => {
    if (id) {
      console.log("found id: ", id);
      const snapshot = await db.collection("sessions").doc(id).get();
      console.log("snapshot: ", snapshot);
      if (!snapshot.empty) {
        const data = snapshot.data();
        setEditMode(true);
        setSessionNumber(data.sessionNumber);
        setStartYear(data.startYear);
        setStartMonth(data.startMonth);
        setIsActive(data.isActive);
      } else {
        setSev("error");
        setMsg("This session does not exists");
        setOpen(true);
      }
    }
  };

  const buildSession = () => {
    if (!editMode) {
      const session = {
        sessionNumber,
        startYear,
        startMonth,
        isActive,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };
      db.collection("sessions")
        .add(session)
        .then((success) => {
          setMsg("Session created successfully!");
          setOpen(true);
          setSev("success");
        })
        .catch((error) => {
          setMsg("Failed to create Session !");
          setOpen(true);
          setSev("error");
        });
    } else {
      db.collection("sessions")
        .doc(id)
        .set(
          {
            startMonth,
            startYear,
            isActive,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then((success) => {
          setMsg("Session updated successfully!");
          setOpen(true);
          setSev("success");
        })
        .catch((error) => {
          setMsg("Failed to update the course");
          setOpen(true);
          setSev("error");
        });
    }
  };

  const clearInputs = () => {
    setSessionNumber("");
    setStartYear("");
    setStartMonth("");
    setIsActive(false);
  };

  const populateYears = () => {
    let curr_year = new Date().getFullYear();
    let yearList = [];
    for (let i = curr_year; i < curr_year + 5; i++) {
      yearList = [
        ...yearList,
        {
          key: i,
          value: i,
        },
      ];
    }
    setYears(yearList);
  };

  const populateMonths = () => {
    let curr_month = new Date().getMonth();
    let monthList = [];
    for (let i = 1; i <= 12; i++) {
      monthList = [
        ...monthList,
        {
          key: i,
          value: monthMap.get(i),
        },
      ];
    }
    setMonths(monthList);
  };

  const validateSessionNumber = async (session_number) => {
    if (editMode == true) {
      return;
    }

    if (session_number) {
      console.log("checking validity of this session number");
      const snapshot = await db
        .collection("sessions")
        .where("sessionNumber", "==", session_number)
        .get();

      if (snapshot.empty) {
        setIsValidSessionNumber(true);
        return;
      }
    }

    setIsValidSessionNumber(false);
    return;
  };

  return (
    <Container
      heading="Create Session "
      align="center"
      enableHeaderButton={false}
     helperText="Here you can start a session for Mangal Vidya Peeth. 😊"
      loading={false}
      handleCancel={() => navigate("/sessions")}
      handleSubmit={buildSession}
      submitButtonText={!editMode ? "Create Session" : "Update"}
      disableWhen={!sessionNumber || !isValidSessionNumber}
    >
      <BoxStack stackStyle={{ gap: "10px" }}>
        {/* Sessison Number */}
        <TextField
          fullWidth
          type="number"
          id="sessionNumber"
          margin="normal"
          label="Session Number"
          placeholder="Eg. 1091923"
          variant="outlined"
          disabled={editMode}
          value={sessionNumber}
          onChange={(e) => setSessionNumber(e.target.value)}
          onBlur={(e) => validateSessionNumber(e.target.value)}
        />

        {sessionNumber && !editMode && (
          <div className="sessionForm__sessionNumber__validator">
            <CheckCircleOutline
              className="sessionForm__sessionNumber__validator__icon"
              color={isValidSessionNumber ? "success" : "error"}
              fontSize="small"
            />
            <span>
              {isValidSessionNumber
                ? "Your session number is unqiue"
                : "This sessionNumber already exists"}
            </span>
          </div>
        )}

        {/* Start Year */}
        <TextField
          id="startYear"
          select
          label="Start Year"
          value={startYear}
          onChange={(e) => setStartYear(e.target.value)}
          style={{ textAlign: "left" }}
        >
          {years.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>

        {/* Start Month */}
        <TextField
          id="startMonth"
          select
          label="Start Month"
          value={startMonth}
          onChange={(e) => setStartMonth(e.target.value)}
          style={{ textAlign: "left" }}
        >
          {months.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>

        {checkExistingActiveSession.id != null &&
        checkExistingActiveSession.data.sessionNumber !== sessionNumber ? (
          <Tooltip title="You already have an Active Session !">
            <Button>
              <Checkbox
                disabled={true}
                checked={false}
                color="primary"
                onChange={() => setIsActive(!isActive)}
              />
              <span>Activate</span>
            </Button>
          </Tooltip>
        ) : (
          <Button onClick={() => setIsActive(!isActive)}>
            <Checkbox
              checked={isActive}
              color="primary"
              onChange={() => setIsActive(!isActive)}
            />
            <span>Activate</span>
          </Button>
        )}
      </BoxStack>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => {
          setOpen(false);
          clearInputs();
          !editMode
            ? sev === "success"
              ? navigate(`/sessions?status=success&sno=${sessionNumber}`)
              : navigate(`/sessions?status=failure&sno=${sessionNumber}`)
            : setTimeout(() => {
                navigate("/sessions");
              }, 500);
        }}
      >
        <Alert
          severity={sev}
          sx={{ width: "100%" }}
          onClose={() => setOpen(false)}
          variant="filled"
        >
          {msg}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default SessionForm;
