import "./SignInForm.css";
import "react-phone-input-2/lib/material.css";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import AppBackdrop from "../custom/backdrop/Backdrop";
import BoxStack from "../custom/box-stack/BoxStack";
import PhoneInput from "react-phone-input-2";
import SocialMedia from "../social-media-handels/SocialMedia";
import logo from "../../../src/mvp_logo.png";
import { useAuth } from "../../contexts/AuthContexts";

function SignInForm() {
  const [mobileNumber, setMobileNumber] = useState(0);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [confirm, setConfirm] = useState();
  const navigate = useNavigate();
  const { setupRecapta, currentUser } = useAuth();
  const [loading, setLoading] = useState();
  const [heading, setHeading] = useState("Sign In");
  const [disableGetOtp, setDisableGetOtp] = useState(false);

  const getOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    setError("");
    if (mobileNumber === "" || mobileNumber === undefined) {
      setLoading(false);
      return setError("Please enter a valid mobile number");
    }

    try {
      const finalMobileNumber = `+${mobileNumber}`;
      setLoading(false);
      setDisableGetOtp(true);
      console.log("disabled get OTP");
      const response = await setupRecapta(finalMobileNumber);

      setConfirm(response);
      setHeading("Verify OTP");
      setFlag(true);
    } catch (err) {
      console.log(err);
      setError("Invalid Phone Number! Try Again");
      setLoading(false);
      // clear contents of recapcha div
      document.getElementById("recaptcha-container").innerHTML = "";
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    if (otp === "" || otp === null) return;

    try {
      setLoading(true);
      setError("");
      setLoading(false);
      await confirm.confirm(otp);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  if (currentUser) {
    navigate("/");
  }

  function renderGetOtp() {
    return (
      <div>
        <Alert
          severity="info"
          className="signin__error"
          sx={{ width: "34ch", textAlign: "left" }}
        >
          <AlertTitle>कृपया ध्यान दें / Please note</AlertTitle>
          <p>
            📌 कृपया अपने व्हाट्सएप नंबर से लॉगिन करें। सत्यापित करने के लिए
            आपको एक एसएमएस ओटीपी प्राप्त होगा।
          </p>

          <p>
            📌 Please login with your Whatsapp number. You will get an SMS OTP
            to verify authentication
          </p>
        </Alert>
        <PhoneInput
          country={"in"}
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e)}
          className="signin__phoneinput"
        />
        <Button
          type="submit"
          onClick={(e) => getOtp(e)}
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginTop: "10px", width: "100%" }}
          disabled={loading || !mobileNumber || disableGetOtp}
        >
          Get OTP
        </Button>
        <div id="recaptcha-container" className="signin__captcha" />
      </div>
    );
  }

  function renderVerifyOtp() {
    return (
      <>
        <TextField
          fullWidth
          type="number"
          id="otp"
          margin="normal"
          label="Enter OTP"
          placeholder="OTP"
          variant="outlined"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />

        <Button
          type="submit"
          onClick={(e) => verifyOtp(e)}
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginTop: "10px" }}
        >
          Verify
        </Button>

        <Button
          type="submit"
          onClick={() => navigate("/")}
          variant="contained"
          color="secondary"
          disableElevation
          style={{ marginTop: "10px" }}
        >
          Cancel
        </Button>
      </>
    );
  }

  return (
    <div className="signin__container">
      <div className="signin" sx={{ minWidth: { xs: "330px", md: "500px" } }}>
        <BoxStack boxDirection="row" boxWidth={"28ch"} stackDirection="column">
          <AppBackdrop open={loading} onClick={() => setLoading(false)} />
          <img className="signin__logo" src={logo} alt="" />
          <h1>{heading}</h1>
          {error && (
            <Alert severity="error" className="signin__error">
              {error}
            </Alert>
          )}
          {!flag && renderGetOtp()}

          {flag && renderVerifyOtp()}
        </BoxStack>
      </div>
      <Link to="/public/help" style={{marginTop: "16px", color: "#0b80d8"}}>
        <span>सहायता / Help?</span>
      </Link>
      <SocialMedia />
      <div className="signin__footer">
        <span>
          Built with <span className="signin__footer__love">❤</span> by ATMA
        </span>
        <span>© 2022-2023</span>
      </div>
    </div>
  );
}

export default SignInForm;
