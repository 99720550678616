import "./Container.css";

import { Add, Cancel } from "@mui/icons-material";
import { Alert, Box, Button, Divider } from "@mui/material";

import AppBackdrop from "../backdrop/Backdrop";
import HeaderContainer from "../header-container/HeaderContainer";
import React from "react";
import { useAuth } from "../../../contexts/AuthContexts";
import { useNavigate } from "react-router-dom";

function Container({
  heading = "<HEADING>",
  align = "left",
  helperText = "<This is a placeholder description>",
  enableDivider = true,
  enableHeaderButton = true,
  btnText = "<BUTTON>",
  ButtonIcon = <Add />,
  handleOnClick,
  enableCancelButton = true,
  handleCancel,
  enableSubmitButton = true,
  submitButtonText = "Submit",
  handleSubmit,
  disableWhen = false,
  alertSeverity = "info",
  alertMessage,
  loading,
  basic = false,
  children,
}) {
  const navigate = useNavigate();
  const container = align == "left" ? "container left" : "container center";

  const handleCancelClick = async () => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate("/");
    }
  };
  return (
    <>
      {!loading && (
        <div className="container___parent">
          <Box className={container}>
            <HeaderContainer
              heading={heading}
              headingType="h1"
              enableHeaderButton={enableHeaderButton}
              ButtonIcon={ButtonIcon}
              handleOnClick={handleOnClick}
              btnTxt={btnText}
              basic
            />

            <p>{helperText}</p>

            {alertMessage && (
              <Alert severity={alertSeverity}>{alertMessage}</Alert>
            )}

            {children}

            {(basic === false && enableSubmitButton) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={disableWhen}
                sx={{ marginTop: "10px", minWidth: "255px" }}
                disableElevation
              >
                {submitButtonText}
              </Button>
            )}

            {(basic === false && enableCancelButton) && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleCancelClick()}
                sx={{ marginTop: "10px", minWidth: "255px" }}
                disableElevation
              >
                Cancel
              </Button>
            )}
          </Box>
          <Box sx={{ flex: { xs: 0, sm: 0.4 } }}></Box>
        </div>
      )}
      <AppBackdrop
        open={loading}
        onClick={() => console.log("backdrop clicked")}
      />
    </>
  );
}

export default Container;
