import { db } from "../firebase";
import firebase from "firebase/compat/app";

const SESSIONS_COLLECTION = "sessions";

export async function getSessions() {
  const snap = await db
    .collection(SESSIONS_COLLECTION)
    .orderBy("timestamp", "asc")
    .get();
  return new Promise((resolve, reject) => {
    if (!snap.empty) {
      const sessions = snap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      resolve(sessions);
    } else {
      console.warn(`no sessions found`);
      resolve([]);
    }
  });
}

export const getSessionById = (uid) => {
  if (!uid) return;
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await db.collection(SESSIONS_COLLECTION).doc(uid).get();
      if (snapshot.exists) {
        const currentActiveBatch = {
          id: snapshot.id,
          data: snapshot.data(),
        };
        resolve(currentActiveBatch);
      } else {
        resolve(null);
      }
    } catch (err) {
      console.log(`error encounted while checking Batch By Uid ${err}`);
      reject(err);
    }
  });
};

export async function extractSessionSnapshot(snapshot) {
  return new Promise((resolve, reject) => {
    const Sessions = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    console.log("found following Sessions = ", Sessions);
    if (Sessions.length > 0) {
      resolve(Sessions);
    } else {
      reject("no batch found");
    }
  });
}

export async function getSessionBySessionId(sessionId) {
  const snap = await db.collection(SESSIONS_COLLECTION).doc(sessionId).get();
  return new Promise((resolve, reject) => {
    if (snap.exists) {
      resolve({ id: snap.id, data: snap.data() });
    } else {
      reject(`no Session found for sessionId=${sessionId}`);
    }
  });
}
