import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import {
  ExpandMore,
  LocalPhone,
  Mail,
  Person,
  Phone,
} from "@mui/icons-material";
import React, { useState } from "react";

import Container from "../custom/container/Container";
import { contacts } from "../../data/constant";
import { getGuid } from "../../helpers/utils";

function HelpAndSupport() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      basic={true}
      heading="🆘 Help"
      helperText="You can find all the help and details of our support team 24 * 7"
    >
      {contacts.map((contact) => (
        <Accordion
          key={getGuid()}
          expanded={expanded === contact.id}
          onChange={handleChange(contact.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${contact.id}bh-content`}
            id={`panel${contact.id}bh-header`}
          >
            <Typography fontWeight="bold">📌 {contact.purpose}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {contact.details.map((contactDetail) => (
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                gap="10px"
                justifyContent="space-between"
                marginY="20px"
                paddingBottom="10px"
                borderBottom="1px solid #e0e0e0"
              >
                <Box
                  display="flex"
                  flexDirection={{ xs: "row", sm: "row" }}
                  gap="10px"
                >
                  <Person />
                  <Typography>{contactDetail.name}</Typography>
                </Box>

                {contactDetail.phoneNumber && (
                  <Box
                    display="flex"
                    flexDirection={{ xs: "row", sm: "row" }}
                    gap="10px"
                  >
                    <Phone />
                    <Typography>
                      <a href={`tel:${contactDetail.phoneNumber}`}>
                        {contactDetail.phoneNumber}
                      </a>
                    </Typography>
                  </Box>
                )}

                {contactDetail.email && (
                  <Box
                    display="flex"
                    flexDirection={{ xs: "row", sm: "row" }}
                    gap="10px"
                  >
                    <Mail />
                    <Typography>
                      <a href={`mailto:${contactDetail.email}`}>
                        {contactDetail.email}
                      </a>
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

export default HelpAndSupport;
