import "./BatchManager.css";

import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTeacherByTeacherId, getTeachers } from "../../api/teacher";
import { useNavigate, useSearchParams } from "react-router-dom";

import AlertDialog from "../custom/alert-dialog/AlertDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { db } from "../../firebase";
import { getCourses } from "../../api/course";
import { getSessions } from "../../api/session";

function BatchManager() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [batches, setBatches] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [actionParams, setActionParams] = useState({ id: null, bname: null });

  //delete feature
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletableBatch, setDeletableBatch] = useState({
    id: null,
    batchName: null,
  });

  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    db.collection("batches")
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setBatches(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
    initialize();
  }, []);

  const initialize = async () => {
    setLoading(true);
    await loadCourses();
    await loadTeachers();
    await loadSessions();
    handleSearchParams();
    setLoading(false);
  };

  const loadTeachers = async () => {
    try {
      const teachersList = await getTeachers();
      setTeachers(teachersList);
    } catch (err) {
      console.log(`Encountered error while load the teachers`);
    }
  };

  const loadCourses = async () => {
    try {
      const courseList = await getCourses();
      setCourses(courseList);
    } catch (err) {
      console.log(`Encoutered error while loading courses`, err);
    }
  };

  const loadSessions = async () => {
    try {
      const sessionsList = await getSessions();
      setSessions(sessionsList);
    } catch (err) {
      console.log(`Encountered error while loading sessions `, err);
    }
  };

  const handleSearchParams = () => {
    if (searchParams.get("status") && searchParams.get("bname")) {
      setActionParams({
        status: searchParams.get("status"),
        bname: searchParams.get("bname"),
      });
      searchParams.delete("status");
      searchParams.delete("bname");
      setSearchParams(searchParams);
    }
  };

  const getCourseName = (course_id) => {
    const courseFound = courses.find((course) => course.id == course_id);
    //console.log("courseFound =", courseFound);
    return courseFound ? courseFound.data.courseName : null;
  };

  const getTeacherName = (teacher_id) => {
    const teacherFound = teachers.find((teacher) => teacher.id == teacher_id);
    //console.log("courseFound =", courseFound);
    return teacherFound ? teacherFound.data.name : null;
  };

  const getSessionName = (session_id) => {
    const sessionFound = sessions.find((session) => session.id == session_id);
    return sessionFound
      ? sessionFound.data.startMonth + " " + sessionFound.data.startYear
      : null;
  };

  const handleAlert = () => {
    if (actionParams?.status === "success" && actionParams?.bname) {
      return (
        <Alert
          className="batchManager__alert"
          variant="outlined"
          severity="success"
        >
          {actionParams.bname + " session created successfully"}
        </Alert>
      );
    }

    if (actionParams?.status === "failure" && actionParams?.bname) {
      return (
        <Alert
          className="batchManager__alert"
          variant="outlined"
          severity="success"
        >
          {"Failed to create" + actionParams.bname}
        </Alert>
      );
    }
  };

  const handleUpdate = (id) => {
    navigate(`/batches/edit/${id}`);
  };

  const handleDelete = (id, batchName) => {
    setDeletableBatch({ id, batchName });
    setOpenDeleteConfirmation(true);
  };

  const handleConfirmDialog = () => {
    setOpenDeleteConfirmation(false);
    db.collection("batches")
      .doc(deletableBatch.id)
      .delete()
      .then((success) => {
        console.log("deleted successfully");
        setMsg("Batch Delete Successfully !");
        setOpen(true);
        setSeverity("success");
      })
      .catch((error) => {
        setMsg("Failed to delete Batch !");
        setOpen(true);
        setSeverity("error");
      });
  };

  return (
    <div className="batchManager">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <div className="batchManager__header">
        <h1>Manage Batches</h1>
        <Button
          startIcon={<Add />}
          variant="contained"
          onClick={() => navigate("/batch/add")}
          disableElevation
        >
          Batch
        </Button>
      </div>

      <Divider />

      <p>Here you can manage batches for Mangal Vidya Peeth</p>

      {handleAlert()}

      {loading && <CircularProgress size={20} />}

      {!loading && batches.length == 0 && (
        <Alert variant="outlined" severity="info">
          No batches found!
        </Alert>
      )}

      {!loading &&
        batches.length > 0 &&
        courses.length > 0 &&
        sessions.length > 0 && (
          <div className="batchManager__tableContainer">
            <TableContainer>
              <Table area-label="batches-table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Batch Name</TableCell>
                    <TableCell align="center">Course</TableCell>
                    <TableCell align="center">Session</TableCell>
                    <TableCell align="center">Primary Teacher</TableCell>
                    <TableCell align="center">Secondary Teacher</TableCell>
                    <TableCell align="center">Slot Day</TableCell>
                    <TableCell align="center">Slot Time</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches.map(
                    ({
                      id,
                      data: {
                        batchName,
                        course,
                        session,
                        primaryTeacher,
                        secondaryTeacher,
                        slotDay,
                        slotTime,
                      },
                    }) => (
                      <TableRow
                        key={id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{batchName}</TableCell>
                        <TableCell align="center">
                          {getCourseName(course) ? (
                            getCourseName(course)
                          ) : (
                            <Chip
                              size="small"
                              label="Deleted"
                              color="error"
                              varient="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {getSessionName(session) ? (
                            getSessionName(session)
                          ) : (
                            <Chip
                              size="small"
                              label="Session Deleted"
                              color="error"
                              varient="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                        {primaryTeacher ? (
                            getTeacherName(primaryTeacher) ? (
                              getTeacherName(primaryTeacher)
                            ) : (
                              <Chip
                                size="small"
                                label="Deleted"
                                color="error"
                                varient="outlined"
                              />
                            )
                          ) : (
                            <Chip
                              size="small"
                              label="Unassigned"
                              color="warning"
                              varient="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {secondaryTeacher ? (
                            getTeacherName(secondaryTeacher) ? (
                              getTeacherName(secondaryTeacher)
                            ) : (
                              <Chip
                                size="small"
                                label="Deleted"
                                color="error"
                                varient="outlined"
                              />
                            )
                          ) : (
                            <Chip
                              size="small"
                              label="Unassigned"
                              color="warning"
                              varient="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">{slotDay}</TableCell>
                        <TableCell align="center">{slotTime}</TableCell>
                        <TableCell align="center">
                          {getCourseName(course) && getSessionName(session) && (
                            <IconButton onClick={() => handleUpdate(id)}>
                              <Edit />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => handleDelete(id, batchName)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

      <AlertDialog
        title="Confirmation"
        description={`Are you sure you want to delete this batch - ${deletableBatch.batchName}?`}
        openDialog={openDeleteConfirmation}
        btn1="Cancel"
        btn2="Delete"
        handleCloseDialog={() => setOpenDeleteConfirmation(false)}
        handleConfirmDialog={handleConfirmDialog}
      />
    </div>
  );
}

export default BatchManager;
