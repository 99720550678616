import { db } from "../firebase";
import drawerOptions from "../data/DrawerOptions";

const USERS_DOCUMENT = "users";

export async function getProfiles() {
  const snap = await db
    .collection(USERS_DOCUMENT)
    .get();
  return new Promise((resolve, reject) => {
    if (!snap.empty) {
      const users = snap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      resolve(users);
    } else {
      console.warn(`no users found`);
      resolve([]);
    }
  });
}

export async function getUserProfile(uid) {
  if (!uid) return;
  return db.collection(USERS_DOCUMENT).where("uid", "==", uid).get();
}

export async function getUserProfileByUid(uid) {
  const snap = await db
    .collection(USERS_DOCUMENT)
    .where("uid", "==", uid)
    .get();
  return new Promise((resolve, reject) => {
    if (!snap.empty) {
      const userDoc = snap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      if (userDoc.length == 1) {
        resolve(userDoc[0]);
      } else {
        reject(`seems multiple user profile exists with same uid=${uid}`);
      }
    } else {
      reject(`no user profile found for uid=${uid}`);
    }
  });
}

export async function getUserProfileObj(uid) {
  if (!uid) return;

  let userProfile;
  let userDocId;

  const snapshot = await db
    .collection(USERS_DOCUMENT)
    .where("uid", "==", uid)
    .get();
  const userProfileResult = snapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));

  userProfile = userProfileResult[0]?.data;
  userDocId = userProfileResult[0]?.id;

  return { userProfile, userDocId };
}

export async function checkIfUserProfileCompleted(uid) {
  console.log("checkIfUserProfileCompleted started :: ", uid);
  if (!uid) return;

  let isProfileCompleted = false;
  let userProfile;
  let userDocId;

  try {
    const userProfileSnapshot = await getUserProfile(uid);
    console.log("found userProfileSnapshot = ", userProfileSnapshot);

    const userProfileResult = userProfileSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    userProfile = userProfileResult[0]?.data;
    userDocId = userProfileResult[0]?.id;
    console.log("found userProfile = ", userProfile);

    if (userProfile && userProfile.firstName && userProfile.lastName) {
      isProfileCompleted = true;
    } else {
      isProfileCompleted = false;
    }
  } catch (err) {
    console.error("Found error during checkIfUserProfileCompleted = ", err);
  }
  return { userDocId, userProfile, isProfileCompleted };
}

export async function saveOrUpdateUser(
  userDocId,
  profileExists,
  userProfileDetails
) {
  if (profileExists) {
    return db
      .collection("users")
      .doc(userDocId)
      .set(userProfileDetails, { merge: true });
  } else {
    userProfileDetails = {
      ...userProfileDetails,
      isUser: true,
      isTeacher: false,
      isAdmin: false,
    };
    return db.collection("users").add(userProfileDetails);
  }
}

export const extractSnapshot = (snapshot) => {
  return new Promise((resolve, reject) => {
    if (!snapshot.empty) {
      const userProfileResult = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      const userProfile = userProfileResult[0]?.data;
      const userDocId = userProfileResult[0]?.id;
      resolve({ userProfile, userDocId });
    } else {
      reject("no profile could be extracted");
    }
  });
};

export const deriveProfileBasedOptions = (userProfile) => {
  return new Promise((resolve, reject) => {
    if (userProfile) {
      console.log(userProfile);
      const { isAdmin, isTeacher, isUser } = userProfile;
      let derivedOptions = [];
      if (isAdmin) {
        derivedOptions = drawerOptions.filter(
          (option) => option.adminAllowed === true && option.isDrawerOption
        );
      } else if (isTeacher) {
        derivedOptions = drawerOptions.filter(
          (option) => option.teacherAllowed === true && option.isDrawerOption
        );
      } else if (isUser) {
        derivedOptions = drawerOptions.filter(
          (option) => option.userAllowed === true && option.isDrawerOption
        );
      }
      console.log(derivedOptions);
      resolve(derivedOptions);
    } else {
      reject("no user profile provided, hence cannot resolve promise");
    }
  });
};

export const deriveDefaultOptions = () => {
  return new Promise((resolve, reject) => {
    const defaultOptions = drawerOptions.filter(
      (option) => option.isDefaultOption === true
    );
    if (defaultOptions.length > 0) {
      resolve(defaultOptions);
    } else {
      reject(
        "No default routes found. Please configure atleast one default route."
      );
    }
  });
};

export const deriveProfileBasedRoutes = (userProfile) => {
  return new Promise((resolve, reject) => {
    if (userProfile) {
      const { isAdmin, isTeacher, isUser } = userProfile;
      let tempAssignRoutes = [];
      if (isAdmin) {
        tempAssignRoutes = drawerOptions.filter(
          (option) => option.adminAllowed === true
        );
      } else if (isTeacher) {
        tempAssignRoutes = drawerOptions.filter(
          (option) => option.teacherAllowed === true
        );
      } else if (isUser) {
        tempAssignRoutes = drawerOptions.filter(
          (option) => option.userAllowed === true
        );
      }
      console.log("Assigned routes = ", tempAssignRoutes);
      resolve(tempAssignRoutes);
    } else {
      reject("no user profile passed to assignRoutesToUser");
    }
  });
};

export const deriveDefaultRoutes = () => {
  return new Promise((resolve, reject) => {
    const defaultRoutes = drawerOptions.filter(
      (option) => option.isDefaultRoute === true
    );
    if (defaultRoutes.length > 0) {
      resolve(defaultRoutes);
    } else {
      reject(
        "No default routes found. Please configure atleast one default route."
      );
    }
  });
};
