import "./BatchForm.css";

import { Alert, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BoxStack from "../custom/box-stack/BoxStack";
import Container from "../custom/container/Container";
import MenuItem from "@mui/material/MenuItem";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";

function BatchForm() {
  const navigate = useNavigate();

  //loading
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);

  //dropdown variables
  const [courses, setCourses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [slotDays, setSlotDays] = useState([]);
  const [slotTimes, setSlotTimes] = useState([]);

  // form variables
  const [course, setCourse] = useState("");
  const [session, setSession] = useState("");
  const [batchName, setBatchName] = useState("");
  const [description, setDescription] = useState("");
  const [slotDay, setSlotDay] = useState("");
  const [slotTime, setSlotTime] = useState("");

  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [teachers, setTeachers] = useState([]);
  const [primaryTeacher, setPrimaryTeacher] = useState("");
  const [secondaryTeacher, setSecondaryTeacher] = useState("");

  useEffect(() => {
    if (courses.length === 0) {
      loadCourses();
    }

    if (sessions.length === 0) {
      loadSessions();
    }

    if (slotDays.length === 0) {
      loadSlotDays();
    }

    if (slotTimes.length === 0) {
      loadSlotTimes();
    }

    if (teachers.length === 0) {
      loadTeachers();
    }
    if (id){
      loadExistingBatch();
    }
  }, []);

  const loadCourses = () => {
    db.collection("courses")
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setCourses(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  const loadSessions = () => {
    db.collection("sessions")
      .where("isActive", "==", true)
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setSessions(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  const loadSlotDays = () => {
    db.collection("slotDays").onSnapshot((snapshot) => {
      setSlotDays(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  };

  const loadSlotTimes = () => {
    db.collection("slotTimes").onSnapshot((snapshot) => {
      setSlotTimes(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  };

  const loadTeachers = () => {
    db.collection("teachers")
      .orderBy("createdOn", "asc")
      .onSnapshot((snapshot) => {
        setTeachers(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
  };

  const createBatch = () => {
    const newBatch = {
      course,
      session,
      batchName,
      description,
      slotDay,
      slotTime,
      primaryTeacher,
      secondaryTeacher,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if(!editMode){
      db.collection("batches")
        .add(newBatch)
        .then((success) => {
          setMsg("Batch created Successfully !");
          setOpen(true);
          setSeverity("success");
        })
        .catch((error) => {
          setMsg("Failed to create Batch !");
          setOpen(true);
          setSeverity("error");
        }).finally(() => {
          clearInputs();
        });
    }
    else{
      db.collection("batches")
        .doc(id)
        .set(newBatch, { merge: true })
        .then((success) => {
          setMsg("Batch updated successfully!");
          setOpen(true);
          setSeverity("success");
          setTimeout(() => {
            navigate("/batches");
          }, 350);
        })
        .catch((error) => {
          setMsg("Failed to update the batch");
          setOpen(true);
          setSeverity("error");
          setTimeout(() => {
            navigate("/batches");
          }, 350);
        }).finally(() => {
          clearInputs();
        });
    }
  };

  const loadExistingBatch = async () => {
    if (id) {
      console.log("id = ", id);
      const batchSnapshot = await db.collection("batches").doc(id).get();
      if (batchSnapshot.empty || batchSnapshot.data() == null) {
        setSeverity("error");
        setMsg("This Batch does not exists");
        setOpen(true);
        setLoading(false);
      } else {
        setEditMode(true);
        const data = batchSnapshot.data();
        setBatchName(data.batchName);
        setDescription(data.description);
        setCourse(data.course);
        setSession(data.session);
        setPrimaryTeacher(data.primaryTeacher);
        setSecondaryTeacher(data.secondaryTeacher);
        setSlotDay(data.slotDay);
        setSlotTime(data.slotTime);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const clearInputs = () => {
    setCourse("");
    setSession("");
    setBatchName("");
    setDescription("");
    setSlotDay("");
    setSlotTime("");
  };

  return (
    <Container
      heading={(!editMode ? "Create " : "Update ") + "Batch"}
      align="center"
      enableHeaderButton={false}
      helperText="Here you can design a batch for Mangal Vidya Peeth. 😊"
      loading={loading}
      handleCancel={() => navigate("/batches")}
      handleSubmit={createBatch}
      submitButtonText={!editMode ? "Create Batch" : "Update"}
      disableWhen={
        !course ||
        !session ||
        !batchName ||
        !description ||
        !primaryTeacher ||
        !slotDay ||
        !slotTime
      }
    >
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          clearInputs();
          severity === "success"
            ? navigate(`/batches?status=success&bname=${batchName}`)
            : navigate(`/batches?status=failure&bname=${batchName}`);
        }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <BoxStack>
        <BoxStack
          boxWidth="25ch"
          stackDirection="column"
          boxStyle={{ flexDirection: "row" }}
        >
          {/* batch Name */}
          <TextField
            fullWidth
            id="batchName"
            margin="normal"
            label="Batch Name (Descriptive)"
            placeholder="Eg. सोम-शुक्र/Weekdays"
            variant="outlined"
            value={batchName}
            onChange={(e) => setBatchName(e.target.value)}
          />

          {/* Course */}
          <TextField
            id="courses"
            select
            label="Choose Course"
            value={course}
            onChange={(e) => setCourse(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {courses.map(({ id, data: { courseName } }) => (
              <MenuItem key={id} value={id}>
                {courseName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="primaryTeacher"
            select
            label="Primary Teacher"
            value={primaryTeacher}
            onChange={(e) => {
              console.log(e);
              setPrimaryTeacher(e.target.value);
            }}
            style={{ textAlign: "left" }}
          >
            {teachers.map(({ id, data: { name } }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          {/* Slot Days */}
          <TextField
            id="slotDays"
            select
            label="Choose Slot Day"
            value={slotDay}
            onChange={(e) => setSlotDay(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {slotDays.map(({ id, data: { day } }) => (
              <MenuItem key={id} value={day}>
                {day}
              </MenuItem>
            ))}
          </TextField>
        </BoxStack>
        <BoxStack
          stackDirection="column"
          boxStyle={{ flexDirection: "row" }}
          boxWidth="25ch"
        >
          {/* batch Descripton */}
          <TextField
            fullWidth={true}
            id="outlined-multiline-flexible"
            label="Description"
            placeholder="Describe you batch"
            multiline
            rows={1}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          {/* Session */}
          <TextField
            id="sessions"
            select
            label="Choose Session"
            value={session}
            onChange={(e) => setSession(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {sessions.map(
              ({
                id,
                data: { sessionNumber, startYear, startMonth, isActive },
              }) => (
                <MenuItem key={id} value={id}>
                  {startMonth + " " + startYear}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            id="secondaryTeacher"
            select
            label="Secondary Teacher"
            value={secondaryTeacher}
            onChange={(e) => setSecondaryTeacher(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {teachers
              .filter(function (e) {
                return e.data.name !== primaryTeacher;
              })
              .map(({ id, data: { name } }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </TextField>

          {/* Slot Days */}
          <TextField
            id="slotTime"
            select
            label="Choose Slot Time"
            value={slotTime}
            onChange={(e) => setSlotTime(e.target.value)}
            style={{ textAlign: "left" }}
          >
            {slotTimes.map(({ id, data: { time } }) => (
              <MenuItem key={id} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </BoxStack>
      </BoxStack>
    </Container>
  );
}

export default BatchForm;
