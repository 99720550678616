import "./CourseManager.css";

import {
  Add,
  BackupTable,
  Delete,
  Edit,
  GridView,
  RemoveRedEye,
  Toc,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCities, getCountries, getStates } from "../../helpers/geographics";

import AlertDialog from "../custom/alert-dialog/AlertDialog";
import BackgroundLetterAvatar from "../custom/avatar/BackgroundLetterAvatar";
import CustomCard from "../custom/card/CustomCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { db } from "../../firebase";
import { timeSince } from "../../helpers/timeSince";
import { useNavigate } from "react-router-dom";

function CourseManager() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [hindi, setHindi] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tileView, setTileView] = useState(true);
  const [listView, setListView] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  // delete feature
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletableCourse, setDeletableCourse] = useState({
    id: null,
    courseName: null,
  });

  useEffect(() => {
    db.collection("courses")
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setCourses(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
  }, []);

  const handleDelete = (id, courseName) => {
    setDeletableCourse({ id, courseName });
    setOpenDeleteConfirmation(true);
  };

  const handleConfirmDialog = () => {
    setOpenDeleteConfirmation(false);
    db.collection("courses")
      .doc(deletableCourse.id)
      .delete()
      .then((success) => {
        console.log("deleted successfully");
        setMsg("Course Deleted Successfully !");
        setOpen(true);
        setSeverity("success");
      })
      .catch((failure) => {
        console.log("failed to delete");
        setMsg("Failed To Delete Course !");
        setOpen(true);
        setSeverity("success");
      });
  };

  return (
    <div className="courseManager">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => { setOpen(false)}}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      {courses.length > 0 && (
        <div className="courseManager__lang__box">
          {/* <div>
            <span>ENG</span>
            <Switch
              checked={hindi}
              onChange={() => setHindi(!hindi)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>हिन्दी</span>
          </div> */}
          <div>
            <IconButton
              onClick={() => {
                setListView(true);
                setTileView(false);
              }}
            >
              <Toc />
            </IconButton>{" "}
            |{" "}
            <IconButton
              onClick={() => {
                setListView(false);
                setTileView(true);
              }}
            >
              <GridView />
            </IconButton>
          </div>
        </div>
      )}

      <div className="courseManager__header">
        <h1 color="primary">{(!hindi && "Manage Courses") || "पाठ्यक्रम प्रबंधित करें"}</h1>
        <Button
          className="courseManager__header__btn"
          startIcon={<Add />}
          variant="contained"
          onClick={() => navigate("/course/add")}
          disableElevation
        >
          {(!hindi && "Course") || "पाठ्यक्रम"}
        </Button>
      </div>

      <Divider />
      <p>
        {(!hindi && "Here you can manage all courses of Mangal Vidya Peeth") ||
          "सभी पाठ्यक्रम यहां प्रबंधित किए जा सकते हैं"}
      </p>

      {loading && <CircularProgress size={20} />}

      {!loading && courses.length > 0 && listView && (
        <div className="courseManager__tableContainer">
          <TableContainer>
            <Table aria-label="simple table" size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {(!hindi && <b>Course Name</b>) || <b>पाठ्यक्रम का नाम</b>}
                  </TableCell>
                  <TableCell align="left">
                    {(!hindi && <b>Description</b>) || <b>विवरण</b>}
                  </TableCell>
                  <TableCell align="right">
                    {(!hindi && <b>Notes</b>) || <b>पाठ्यक्रम का नाम</b>}
                  </TableCell>
                  <TableCell align="right">
                    {(!hindi && <b>Created/Updated</b>) || (
                      <b>बनाया/बदला गया</b>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {(!hindi && <b>Actions</b>) || <b>कार्यवाई</b>}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courses.map(
                  ({
                    id,
                    data: {
                      courseName,
                      courseNameTrns,
                      description,
                      descriptionTrns,
                      notes,
                      notesTrns,
                      createdBy,
                      timestamp,
                    },
                  }) => (
                    <TableRow
                      key={id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        <div className="courseManager__courseNameRow">
                          <BackgroundLetterAvatar
                            value={(!hindi && courseName) || courseNameTrns}
                          />
                          <span>
                            {(!hindi && courseName) || courseNameTrns}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {(!hindi && description.substring(0, 50) + "...") ||
                          descriptionTrns.substring(0, 50) + "..."}
                      </TableCell>
                      <TableCell align="right">
                        {(!hindi && notes) || notesTrns}
                      </TableCell>
                      <TableCell align="right">
                        {timeSince(timestamp, hindi)}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => navigate(`/course/edit/${id}`)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(id, courseName)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {!loading && courses.length === 0 && (
        <Alert variant="outlined" severity="info">
          No course found!
        </Alert>
      )}

      <div className="courseManager__cardContainer">
        {!loading &&
          courses.length > 0 &&
          tileView &&
          courses.map(
            ({
              id,
              data: {
                courseName,
                courseNameTrns,
                description,
                descriptionTrns,
                notes,
                notesTrns,
                createdBy,
                timestamp,
              },
            }) => (
              <CustomCard
                key={id}
                title={courseName}
                description={description}
                lastAction={timeSince(timestamp, hindi)}
                handleEdit={() => navigate(`/course/edit/${id}`)}
                handleDelete={() => handleDelete(id, courseName)}
              />
            )
          )}
      </div>

      <AlertDialog
        title="Confirmation"
        description={`Are you sure you want to delete this course - ${deletableCourse.courseName}?`}
        openDialog={openDeleteConfirmation}
        btn1="Cancel"
        btn2="Delete"
        handleCloseDialog={() => setOpenDeleteConfirmation(false)}
        handleConfirmDialog={handleConfirmDialog}
      />
    </div>
  );
}

export default CourseManager;
