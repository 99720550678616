import React, { useEffect, useState } from "react";
import { deriveDefaultOptions, deriveProfileBasedOptions, extractSnapshot, getUserProfile } from "../../../api/user";

import { Apps } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Footer from "../../footer/Footer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useAuth } from "../../../contexts/AuthContexts";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../contexts/ProfileContexts";

function AppDrawer({ drawerWidth, container, mobileOpen, handleDrawerToggle }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const {setAuthorizations} = useProfile();
  const [appDrawerOptions, setAppDrawerOptions] = useState([]);

  useEffect(() => {
    initializeDrawers();
  }, []);

  const initializeDrawers = async () => {
      try{
          const snapshot = await getUserProfile(currentUser.uid);
          const { userProfile, userDocId } = await extractSnapshot(snapshot);
          const options = await deriveProfileBasedOptions(userProfile);
          setAuthorizations(options);
          setAppDrawerOptions(options);
      }
      catch(err) {
            console.log("error while assigning routes to user ", err);
            const options = await deriveDefaultOptions();
            setAuthorizations(options);
            setAppDrawerOptions(options);
      }
  };

  const drawer = (options) => (
    <div>
      <Toolbar />
      {/* <Divider /> */}
      <List>
        {options.map(
          ({ id, icon, label, path, secondaryOption }) =>
            !secondaryOption && (
              <ListItem
                button
                key={id}
                onClick={() => {
                  navigate(path);
                  handleDrawerToggle();
                }}
              >
                <ListItemIcon>{(icon && icon) || <Apps />}</ListItemIcon>

                <ListItemText primary={label} />
              </ListItem>
            )
        )}
      </List>
      {options.filter((op) => op.secondaryOption === undefined)?.length > 0 && <Divider />}
      {options.map(
        ({ id, icon, label, path, secondaryOption }) =>
          secondaryOption && (
            <ListItem
              button
              key={id}
              onClick={() => {
                navigate(path);
                handleDrawerToggle();
              }}
            >
              <ListItemIcon>{(icon && icon) || <Apps />}</ListItemIcon>

              <ListItemText primary={label} />
            </ListItem>
          )
      )}
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="persistent"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer(appDrawerOptions)}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer(appDrawerOptions)}
      </Drawer>
    </Box>
  );
}

export default AppDrawer;
