import "./TeacherManager.css";

import { Add, Delete, Edit } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Divider, IconButton, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

import AlertDialog from "../custom/alert-dialog/AlertDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

function TeacherManager() {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);

  // snakbar
  const [level, setLevel] = useState("info");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  // delete teacher
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletableTeacher, setDeletableTeacher] = useState({
    id: null,
    name: null,
  });

  useEffect(() => {
    loadTeachers();
  }, []);

  const loadTeachers = () => {
    db.collection("teachers")
      .orderBy("createdOn", "asc")
      .onSnapshot((snapshot) => {
        setTeachers(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
  };

  const handleUpdate = (id) => {
    navigate(`/teacher/edit/${id}`);
  };

  const handleDelete = (id, name) => {
    setDeletableTeacher({ id, name });
    setOpenDeleteConfirmation(true);
  };

  const handleConfirmDialog = () => {
    setOpenDeleteConfirmation(false);
    db.collection("teachers")
      .doc(deletableTeacher.id)
      .delete()
      .then((success) => {
        setMsg(`${deletableTeacher.name} deleted successfully`);
        setLevel("success");
        setOpen(true);
      })
      .catch((failure) => {
        setMsg(`Failed to delete ${deletableTeacher.name}`);
        setLevel("error");
        setOpen(true);
      });
  };

  return (
    <div className="teacherManager">
      <div className="teacherManager__header">
        <h1>Manage Teachers</h1>
        <Button
          className="teacherManager__header__btn"
          startIcon={<Add />}
          variant="contained"
          onClick={() => navigate("/teacher/add")}
          disableElevation
        >
          Teacher
        </Button>
      </div>
      <Divider />

      <p>Here you can manage all the teacher of Mangal Vidya Peeth</p>

      {loading && <CircularProgress size={20} />}

      {!loading && teachers.length == 0 && (
        <Alert variant="outlined" severity="info">
          No teacher found!
        </Alert>
      )}

      {!loading && teachers.length > 0 && (
        <div className="teacherManager__tableContainer">
          <TableContainer>
            <Table aria-label="teacher manager table" size="small" stickyHeader>
              <TableHead>
                <TableCell align="left">Teacher Name</TableCell>
                <TableCell align="left">Mobile Number</TableCell>
                <TableCell align="left">Country</TableCell>
                <TableCell align="left">State</TableCell>
                <TableCell align="left">City/Town/Village</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {teachers.map(({ id, data: { name, phoneNumber, country, state, city, townOrVillage } }) => (
                  <TableRow key={id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{phoneNumber}</TableCell>
                    <TableCell align="left">{country}</TableCell>
                    <TableCell align="left">{state}</TableCell>
                    <TableCell align="left">{city ? city : townOrVillage}</TableCell>
                    <TableCell align="left">
                      <div>
                        <IconButton
                          aria-label="edit-btn"
                          onClick={() => handleUpdate(id)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="edit-btn"
                          onClick={() => handleDelete(id, name)}
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <AlertDialog
        title="Confirmation"
        description={`Are you sure you want to delete teacher - ${deletableTeacher.name}?`}
        openDialog={openDeleteConfirmation}
        btn1="Cancel"
        btn2="Delete"
        handleCloseDialog={() => setOpenDeleteConfirmation(false)}
        handleConfirmDialog={handleConfirmDialog}
      />

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={level}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default TeacherManager;
