import "./Profile.css";

import { Alert, Box, Divider, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  checkIfUserProfileCompleted,
  getUserProfile,
  saveOrUpdateUser,
} from "../../api/user";
import { getCities, getCountries, getStates } from "../../helpers/geographics";
import { useNavigate, useParams } from "react-router-dom";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AlertDialog from "../custom/alert-dialog/AlertDialog";
import AppBackdrop from "../custom/backdrop/Backdrop";
import BoxStack from "../custom/box-stack/BoxStack";
import Checkbox from "@mui/material/Checkbox";
import Container from "../custom/container/Container";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import PhoneInput from "react-phone-input-2";
import firebase from "firebase/compat/app";
import { useAuth } from "../../contexts/AuthContexts";

function Heading({ children }) {
  return <h2 style={{ marginBottom: "10px" }}>{children}</h2>;
}

function CustomStack({ children }) {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      gap={{ xs: "0px", sm: "13px" }}
      alignItems="baseline"
      justifyContent="center"
      marginBottom="13px"
      width={{ xs: "100%", sm: "650px" }}
      flexWrap="no-wrap"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
        "& .react-tel-input .form-control": { width: "100%" },
      }}
    >
      {children}
    </Stack>
  );
}

function Profile() {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [loading, setLoading] = useState(true);

  // personal details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(null);
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState(currentUser.phoneNumber);
  const [isItWhatsAppNumber, setIsItWhatsAppNumber] = useState(true);
  const [whatsAppMobileNumber, setWhatsAppMobileNumber] = useState();

  // address details
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [townOrVillage, setTownOrVillage] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");

  // family details
  const [familyMembers, setFamilyMembers] = useState([]);
  const [familyMember, setFamilyMember] = useState();
  const [fmFisrtName, setFmFirstName] = useState();
  const [fmLastName, setFmLastName] = useState();
  const [fmDob, setFmDob] = useState();
  const [fmMobileNumber, setFmMobileNumber] = useState();

  // dropdowns
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [userProfile, setUserProfile] = useState();
  const [userProfileExists, setUserProfileExists] = useState(false);
  const [incompleteProfileAlertOpen, setIncompleteProfileAlertOpen] =
    useState(false);
  const [userDocId, setUserDocId] = useState();
  const { status } = useParams();

  useEffect(() => {
    setLoading(true);
    loadCountries();
    loadStatusMsg();
    loadUserProfile();
  }, []);

  const loadStatusMsg = () => {
    if (status === "incomplete") {
      setIncompleteProfileAlertOpen(true);
      setWhatsAppMobileNumber(currentUser.phoneNumber);
      setIsItWhatsAppNumber(true);
    }
  };

  const genders = [
    { key: "M", value: "Male" },
    { key: "F", value: "Female" },
  ];

  const loadCountries = async () => {
    const response = await getCountries();
    console.log("countries loaded");
    setCountries([...response]);
  };

  const handleCountrySelect = async (selectedCountry) => {
    const response = await getStates(selectedCountry);
    setCountry(selectedCountry);
    setStates([...response]);
  };

  const handleStateSelect = async (selectedState) => {
    const response = await getCities(selectedState);
    setState(selectedState);
    setCities([...response]);
  };

  const handleSubmit = async () => {
    let wapMobileNumber = whatsAppMobileNumber;
    if (!wapMobileNumber.startsWith("+")) {
      wapMobileNumber = `+${wapMobileNumber}`;
    }
    const userProfileDetails = {
      ...userProfile,
      firstName,
      gender,
      lastName,
      dob,
      mobileNumber,
      isItWhatsAppNumber,
      whatsAppMobileNumber: wapMobileNumber,
      email,
      addressLine1,
      addressLine2,
      landmark,
      country,
      state,
      city,
      townOrVillage,
      pincode,
      uid: currentUser.uid,
      updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
    };

    try {
      await saveOrUpdateUser(userDocId, userProfileExists, userProfileDetails);
      /**
       * !temporary solution to hard load the dashboard after profile completion.
       * !need to optimize this
       */
      window.location.href = "/";
    } catch (err) {
      console.log(err.message);
    }
  };

  const loadUserProfile = async () => {
    const {
      userDocId: docId,
      userProfile: profile,
      isProfileCompleted,
    } = await checkIfUserProfileCompleted(currentUser.uid);
    console.log("profile = ", profile);
    if (profile) {
      console.log("profile completed = ", isProfileCompleted);
      console.log("user profile = ", profile);

      setUserProfileExists(true);
      setUserDocId(docId);

      // set personal details
      setFirstName(profile.firstName);
      setLastName(profile.lastName);
      setGender(profile.gender);
      setDob(profile.dob.toDate());
      setEmail(profile.email);

      // set address details
      setAddressLine1(profile.addressLine1);
      setAddressLine2(profile.addressLine2);
      setLandmark(profile.landmark);
      //const userCountry = countries.find(country => country.country_name === userProfile.country);
      setCountry(profile.country);

      const stateList = await getStates(profile.country);
      setStates(stateList);
      setState(profile.state);

      const cityList = await getCities(profile.state);
      setCities(cityList);
      setCity(profile.city);

      setTownOrVillage(profile.townOrVillage);
      setPincode(profile.pincode);

      setIsItWhatsAppNumber(profile.isItWhatsAppNumber);
      setWhatsAppMobileNumber(profile.whatsAppMobileNumber);
    } else {
      console.log("could not find userProfile, hence unable to load profile");
      setCountry("India");
      const stateList = await getStates("India");
      setStates(stateList);
    }
    setLoading(false);
  };

  return (
    <>
      <AppBackdrop open={loading}></AppBackdrop>
      {!loading && (
        <Container
          heading="✨ My Profile"
          align="center"
          enableHeaderButton={false}
          helperText="You can update your profile details below"
          loading={loading}
          handleSubmit={handleSubmit}
          submitButtonText="Update Profile"
          disableWhen={
            !firstName ||
            !lastName ||
            !dob ||
            !gender ||
            !mobileNumber ||
            !whatsAppMobileNumber ||
            !email ||
            !addressLine1 ||
            !addressLine2 ||
            !country ||
            !state ||
            (!city && !townOrVillage) ||
            !pincode
          }
        >
          <Heading>Personal Details</Heading>

          <CustomStack>
            {/* FirstName */}
            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="First Name*"
              placeholder="Ex. Rishabh"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            {/* LastName */}
            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="Last Name*"
              placeholder="Ex. Jain"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </CustomStack>

          <CustomStack>
            {/* Gender */}
            <TextField
              id="gender"
              select
              label="Gender*"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              style={{ textAlign: "left" }}
            >
              {genders.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>

            {/* DOB */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date of Birth*"
                value={dob}
                onChange={(newValue) => {
                  setDob(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ marginTop: { xs: "20px", sm: "0px" } }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </CustomStack>

          <Heading>Contact Details</Heading>

          <CustomStack>
            {/* Email */}
            <TextField
              fullWidth
              type="email"
              id="name"
              margin="normal"
              label="Email Address*"
              placeholder="Ex. rishabh.jain@gmail.com"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </CustomStack>

          <CustomStack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Checkbox
                aria-label="secondaryNumber"
                checked={isItWhatsAppNumber}
                onChange={(e) => {
                  setIsItWhatsAppNumber(e.target.checked);
                  if (e.target.checked === true) {
                    setWhatsAppMobileNumber(mobileNumber);
                  } else {
                    setWhatsAppMobileNumber("");
                  }
                }}
              />
              <span style={{ textAlign: "left" }}>
              मेरा लॉगिन नंबर और व्हाट्सएप नंबर एक ही है /
              My login number and WhatsApp number are same
              </span>
            </Stack>
          </CustomStack>

          <CustomStack>
            <PhoneInput
              country={"in"}
              value={mobileNumber}
              disabled={mobileNumber ? true : false}
              onChange={(e) => setMobileNumber(e)}
              specialLabel="Mobile Number*"
              containerClass="profile__phone__number"
            />

            <PhoneInput
              country={"in"}
              value={whatsAppMobileNumber}
              disabled={isItWhatsAppNumber}
              onChange={(e) => setWhatsAppMobileNumber(e)}
              className="profile__phone__number"
              specialLabel="WhatsApp Mobile Number*"
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            />
          </CustomStack>

          <Heading>Address Details</Heading>

          <CustomStack>
            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="Address Line 1*"
              placeholder="14th Floor, Flat no. 4"
              variant="outlined"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
            />

            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="Address Line 2*"
              placeholder="DLF Garden City"
              variant="outlined"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
            />
          </CustomStack>

          <CustomStack>
            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="Landmark (Optional)"
              placeholder="near Amazon Sports Complex"
              variant="outlined"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
            />

            {/* Country */}
            <TextField
              id="country"
              select
              label="Country"
              value={country}
              onChange={(e) => handleCountrySelect(e.target.value)}
              style={{ textAlign: "left" }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              {countries.map((option) => (
                <MenuItem
                  key={option.country_short_name}
                  value={option.country_name}
                >
                  {option.country_name}
                </MenuItem>
              ))}
            </TextField>
          </CustomStack>

          <CustomStack>
            {/* State */}
            <TextField
              id="state"
              select
              label="State*"
              value={state}
              disabled={!country}
              onChange={(e) => handleStateSelect(e.target.value)}
              style={{ textAlign: "left" }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              {states.map((option) => (
                <MenuItem key={option.state_name} value={option.state_name}>
                  {option.state_name}
                </MenuItem>
              ))}
            </TextField>

            {/* City */}
            <TextField
              id="city"
              select
              label="City*"
              value={city}
              disabled={!state}
              onChange={(e) => setCity(e.target.value)}
              style={{ textAlign: "left" }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              {cities.map((option) => (
                <MenuItem key={option.city_name} value={option.city_name}>
                  {option.city_name}
                </MenuItem>
              ))}
            </TextField>
          </CustomStack>

          <CustomStack>
            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="Town/Village (Optional)*"
              placeholder="Ex. Amayan"
              variant="outlined"
              value={townOrVillage}
              onChange={(e) => setTownOrVillage(e.target.value)}
            />

            <TextField
              fullWidth
              type="text"
              id="name"
              margin="normal"
              label="Pincode*"
              placeholder="Ex. 21312"
              variant="outlined"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </CustomStack>
        </Container>
      )}

      <AlertDialog
        openDialog={incompleteProfileAlertOpen}
        title="Action required"
        description="Please complete your profile"
        btn1="Logout"
        btn2="Continue"
        handleCloseDialog={logout}
        confirmBtnColor="success"
        handleConfirmDialog={() => setIncompleteProfileAlertOpen(false)}
      />
    </>
  );
}

export default Profile;
