import React, { useEffect, useState } from "react";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {
  deriveDefaultRoutes,
  deriveProfileBasedRoutes,
  extractSnapshot,
  getUserProfile
} from "../../../api/user";

import Dashboard from "../../dashboard/Dashboard";
import PageNotFound from "../../page-not-found/PageNotFound";
import PrivateRoute from "../../custom/private-route/PrivateRoute";
import PublicHelpAndSupport from "../../help-and-support/PublicHelpAndSupport";
import SignInForm from "../../sign-in/SignInForm";
import { useAuth } from "../../../contexts/AuthContexts";

function AppRoutes() {
  const { currentUser } = useAuth();
  const [finalRoutes, setFinalRoutes] = useState([]);

  useEffect(() => {
    console.log("currentUser = ", currentUser);
    if (currentUser) {
      loadRoutes();
    } else {
      loadDefaultRoutes();
    }
  }, []);

  const loadRoutes = async () => {
    try {
      const snapshot = await getUserProfile(currentUser.uid);
      const { userProfile } = await extractSnapshot(snapshot);
      const assingedRoutes = await deriveProfileBasedRoutes(userProfile);
      setFinalRoutes(assingedRoutes);
    } catch (err) {
      console.log("error while assigning routes to user : ", err);
      loadDefaultRoutes();
    }
  };

  const loadDefaultRoutes = async () => {
    try {
      const assignedDefaultRoutes = await deriveDefaultRoutes();
      setFinalRoutes(assignedDefaultRoutes);
    } catch (err) {
      console.log("error while assigning default routes to user : ", err);
    }
  };

  return (
    <>
      <Routes>
        {/* Secured Dashboard */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
        </Route>

        {finalRoutes.map(({ path, component }) => (
          <Route key={path} path="/" element={<PrivateRoute />}>
            <Route path={path} element={component} />
          </Route>
        ))}
        <Route path="/signin" element={<SignInForm />} />
        <Route path="/public/help" element={<PublicHelpAndSupport />} />

        {/* Page not found */}
        <Route exact path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
