import "./Dashboard.css";

import { Alert, Box, Button, Divider, Grid, Paper } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { checkIfUserProfileCompleted, getUserProfile } from "../../api/user";

import AppBackdrop from "../custom/backdrop/Backdrop";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckIcon  from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Container from "../custom/container/Container";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from '@mui/material/Typography';
import { db } from "../../firebase";
import { getActiveEnrollmentByUid } from "../../api/enroll";
import { getBatchesById } from "../../api/batch";
import { getCourseById } from "../../api/course";
import { getGreeting } from "../../helpers/timeSince";
import { getGuid } from "../../helpers/utils";
import { getSessionById } from "../../api/session";
import { useAuth } from "../../contexts/AuthContexts";
import { useProfile } from "../../contexts/ProfileContexts";

function Dashboard() {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [isAlreadyEnrolled, setAlreadyEnrolledFlag] = useState(false);
  const [activeEnrollments, setActiveEnrollments] = useState([]);

  useEffect(() => {
    loadUserProfile(currentUser);
  }, []);

  const loadUserProfile = async (currentUser) => {
    setLoading(true);
    const { userProfile: profile, isProfileCompleted: isCompleted } =
      await checkIfUserProfileCompleted(currentUser.uid);
    if (!isCompleted) {
      navigate("/profile/incomplete");
    }
    setIsProfileCompleted(isCompleted);
    setUserProfile(profile);
    if (profile.isUser && !profile.isAdmin && !profile.isTeacher) {
      const checkActiveEnrollment = await getActiveEnrollmentByUid(currentUser.uid);
      if(!!checkActiveEnrollment){
        setAlreadyEnrolledFlag(true);
        for(const item of checkActiveEnrollment){
          const getBatch = await getBatchesById(item?.data.batch);
          const getCourse = await getCourseById(item?.data.course);
          const getSession = await getSessionById(getBatch?.data.session);
          if(getCourse && getSession && getBatch){
            activeEnrollments.push({
              "id": item.id,
              "enrollment": item.data,
              "batch": getBatch != null ? getBatch.data:null,
              "course": getCourse != null ? getCourse.data:null,
              "session": getSession != null ? getSession.data:null
            });
          }
        }
        const getIndexForBatchForActiveSession = activeEnrollments.findIndex(item => item.session?.isActive);
        const getBatchForActiveSession = activeEnrollments.find(item => item.session?.isActive);
        if(getBatchForActiveSession !== -1){
          activeEnrollments.splice(getIndexForBatchForActiveSession, 1, getBatchForActiveSession);
        }
        setActiveEnrollments(activeEnrollments);
      }
    }
    setLoading(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/signin");
    } catch (err) {
      console.log(err.message);
    }
  };

  const isUser = !!userProfile && userProfile.isUser && !userProfile.isAdmin && !userProfile.isTeacher;

  return (
    <>
      <AppBackdrop open={loading} />
      {!loading && !!userProfile && !userProfile.isAdmin && (
        <>
          <h1 className="dashboard__greeting__heading" style={{ textAlign: "center" }}>{"Welcome to MVP portal"}</h1>
          <Divider />
          <br />
        </>
      )}
      {!loading && (
        <>
          <h2 style={{ textAlign: "left" }}>
            {"Hi " + (userProfile ? userProfile.firstName : "")}
          </h2>
          <h2 className="dashboard__greeting__heading">{getGreeting()}</h2>
        </>
      )}
      {!loading && !isProfileCompleted && (
        <Alert severity="warning" className="dashboard__alert">
          Please complete your<Link to="/profile"> profile</Link>{" "}
        </Alert>
      )}
      {!loading && isProfileCompleted && isUser && !isAlreadyEnrolled && (
        <Alert severity="success" className="dashboard__alert">
          कोर्स के लिए नामांकन करने के लिए कृपया{" "}
          <Link to="/enrollment/new">
            <strong>यहां</strong>
          </Link>{" "}
          क्लिक करें/Please click{" "}
          <Link to="/enrollment/new">
            <strong>here</strong>
          </Link>{" "}
          to enroll for a course
        </Alert>
      )}
      { !loading && isProfileCompleted && isUser && isAlreadyEnrolled && (
        <Box>
        <Alert severity="success" className="dashboard__alert">
          आपका नीचे दिए पाठ्यक्रम में नामांकन हो गया है / You have successfully enrolled in below courses
          <br />
          नए नामांकन करने के लिए कृपया{" "}
        <Link to="/enrollment/new">
            <strong>यहां</strong>
          </Link>{" "}
          क्लिक करें / Please click{" "}
          <Link to="/enrollment/new">
            <strong>here</strong>
          </Link>{" "}
          for a new enrollment
        </Alert>
        <Container
          heading="Active Enrollments"
          loading={loading}
          helperText="Please find below your active enrollments"
          enableDivider={true}
          enableHeaderButton={false}
          enableSubmitButton={false}
          enableCancelButton={false}
          // handleSubmit={enrollStudent}
          align="center"
        >
          <br />
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              { activeEnrollments.length > 0 && activeEnrollments.map((item) => (
                <Grid item xs={12} md={6} lg={4} key={item?.id || getGuid()}>
                <Paper elevation={3}>
                  <Card>
                    <CardContent>
                      <Typography sx={{ mb: 1.5 }} variant="h8" component="div">
                        <strong>Session: </strong>{item?.session?.startMonth + ' ' + item?.session?.startYear}
                      </Typography>
                      <Typography variant="h8" sx={{ mb: 1.5 }} component="div">
                      <strong>Course: </strong>{item?.course?.courseNameTrns + ' ( ' + item?.course?.courseName + ' )'}
                      </Typography>
                      <Typography variant="h8" sx={{ mb: 1.5 }} component="div">
                      <strong>Batch Name: </strong>{item?.batch?.batchName}
                      </Typography>
                      <Typography variant="h8" sx={{ mb: 1.5 }} component="div">
                      <div style={{ "display":"inline-flex" }}>
                        <strong>Material Required: </strong>
                        {item?.enrollment?.materialDeliveryPref ? <CheckIcon /> : <CloseIcon />}
                      </div>
                        <br />
                      </Typography>
                    </CardContent>
                  </Card>
                </Paper>
                </Grid>
              ))}
            </Grid>
        </Container>
        </Box>
      )}
    </>
  );
}

export default Dashboard;
