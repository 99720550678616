import React, { useContext, useState } from "react";

import { AccountCircle } from "@mui/icons-material";
import Profile from "../components/profile/Profile";
import { db } from "../firebase";
import { useAuth } from "./AuthContexts";

const ProfileContext = React.createContext();

export function useProfile() {
  return useContext(ProfileContext);
}

function ProfileProvider({ children }) {
  const [userProfile, setUserProfile] = useState();
  const { currentUser, loading } = useAuth();
  const [profileLoading, setProfileLoading] = useState(true);
  const [allowedPages, setAllowedPages] = useState([{
    id: 107,
    icon: <AccountCircle />,
    label: "My Profile",
    path: "/profile",
    component: <Profile />,
    isDrawerOption: true,
    secondaryOption: true,
    userAllowed: true,
    teacherAllowed: true,
    adminAllowed: true
  }]);

  const initializeUserProfile = async () => {
    console.log("initializing user profile for user = ", currentUser);
    if (currentUser) {
      const user = currentUser;
      console.log(user.uid);
      const snapshot = await db
        .collection("users")
        .where("uid", "==", user.uid)
        .get();
      console.log(snapshot);

      const res = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      const profile = res[0].data;
      setUserProfile(profile);
      setProfileLoading(false);
    }
  };

  const setAuthorizations = (authorizations) => {
    return new Promise((resolve, reject) => {
        if(authorizations.length > 0){
          setAllowedPages(authorizations);
          console.log("authorizations set successfully = ", allowedPages);
          resolve("Authorizations set successfully");
        }
        else{
          reject("failed to set authorizations");
        }
    })
  }

  const value = {
    userProfile,
    initializeUserProfile,
    setAuthorizations,
    allowedPages
  };

  return (
    <ProfileContext.Provider value={value}>{!loading && children}</ProfileContext.Provider>
  );
}

export default ProfileProvider;
