import { Alert, Button, Divider } from "@mui/material";

import { Add } from "@mui/icons-material";
import React from "react";

function HeaderContainer({
  heading,
  headingType="h1",
  enableHeaderButton,
  ButtonIcon = <Add />,
  handleOnClick,
  btnText,
  enableDivider=true,
  basic=false
}) {
  return (
    <>
      <div className="container__header__section">
        {headingType != "h2" && <h1 className="container__heading">{heading}</h1>}
        {headingType == "h2" && <h2 className="container__heading">{heading}</h2>}
        {(basic === false && enableHeaderButton) && (
          <Button
            startIcon={ButtonIcon}
            variant="contained"
            onClick={handleOnClick}
            disableElevation
          >
            {btnText}
          </Button>
        )}
      </div>
      {enableDivider && <Divider />}
    </>
  );
}

export default HeaderContainer;
