import "./EnrollmentManager.css";

import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Badge,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getBatchByBatchId, getBatches } from "../../api/batch";
import { getCourseByCourseId, getCourses } from "../../api/course";
import { getProfiles, getUserProfileByUid } from "../../api/user";
import { getSessionBySessionId, getSessions } from "../../api/session";
import { getTeacherByTeacherId, getTeachers } from "../../api/teacher";

import BackgroundLetterAvatar from "../custom/avatar/BackgroundLetterAvatar";
import DownloadExport from "../custom/download-export/DownloadExport";
import { db } from "../../firebase";
import { getEnrollments } from "../../api/enroll";
import { getGuid } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

function EnrollmentManager() {
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);

  // !NOTE pagination related state
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      // get all the enrollments
      const enrollmentList = await getEnrollments();

      // get all the teachers
      const teacherList = await getTeachers();

      // get all the batches
      const batchList = await getBatches();

      // get all the sessions
      const sessionList = await getSessions();

      // get all the courses
      const courseList = await getCourses();

      // get all the profiles
      const userProfileList = await getProfiles();

      // link all data together and display
      const enrollmentsCombined = enrollmentList.map(
        ({
          id: enrollmentId,
          data: {
            course: courseId,
            batch: batchId,
            uid: studentId,
            materialDeliveryPref,
            timestamp,
          },
        }) => {
          const course = courseList.find((c) => c.id === courseId);
          const batch = batchList.find((b) => b.id === batchId);
          const session = batch
            ? sessionList.find((s) => s.id === batch.data.session)
            : null;
          const primaryTeacher =
            batch && batch.data.primaryTeacher
              ? teacherList.find((t) => t.id === batch.data.primaryTeacher)
              : null;
          const secondaryTeacher =
            batch && batch.data.secondaryTeacher
              ? teacherList.find((t) => t.id === batch.data.secondaryTeacher)
              : null;
          const student = studentId
            ? userProfileList.find((u) => u.data.uid === studentId)
            : null;

          return [
            {
              label: "Enrollment Id",
              value: enrollmentId,
              align: "left",
              minWidth: 170,
              hidden: true,
            },
            {
              label: "Course Id",
              value: course?.id,
              align: "left",
              minWidth: 170,
              hidden: true,
            },
            {
              label: "Session Id",
              value: session?.id,
              align: "left",
              minWidth: 170,
              hidden: true,
            },
            {
              label: "Batch Id",
              value: batch?.id,
              align: "left",
              minWidth: 170,
              hidden: true,
            },
            {
              label: "Course Name",
              value: courseId
                ? (course
                  ? course?.data?.courseName
                  : "Deleted")
                : null,
              align: "left",
              minWidth: 200,
            },
            {
              label: "Batch Name",
              value: batchId
                ? (batch
                  ? batch?.data?.batchName
                  : "Deleted")
                : null,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Session Name",
              value:
                session?.data?.startMonth && session?.data?.startYear
                  ? `${session.data.startMonth} ${session.data.startYear}`
                  : null,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Slot Day",
              value: batch?.data?.slotDay,
              align: "left",
              minWidth: 200,
            },
            {
              label: "Slot Time",
              value: batch?.data?.slotTime,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Primary Teacher (PT)",
              value: primaryTeacher ? primaryTeacher.data.name : null,
              align: "left",
              minWidth: 190,
            },
            {
              label: "PT Phone Number",
              value: primaryTeacher ? primaryTeacher.data.phoneNumber : null,
              align: "left",
              minWidth: 170,
            },
            {
              label: "PT City",
              value: primaryTeacher ? primaryTeacher.data.city : null,
              align: "left",
              minWidth: 150,
            },
            {
              label: "Secondary Teacher (ST)",
              value: secondaryTeacher ? secondaryTeacher.data.name : null,
              align: "left",
              minWidth: 210,
            },
            {
              label: "ST Phone Number",
              value: secondaryTeacher
                ? secondaryTeacher.data.phoneNumber
                : null,
              align: "left",
              minWidth: 170,
            },
            {
              label: "ST City",
              value: secondaryTeacher ? secondaryTeacher.data.city : null,
              align: "left",
              minWidth: 150,
            },
            {
              label: "Student Name",
              value: student?.data
                ? `${student.data.firstName} ${student.data.lastName}`
                : null,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Gender",
              value: student?.data.gender,
              align: "left",
              minWidth: 170,
            },
            {
              label: "DOB",
              value: student?.data.dob
                ?.toDate()
                .toJSON()
                .slice(0, 10)
                .replace(/-/g, "/"),
              align: "left",
              minWidth: 170,
            },
            {
              label: "Phone Number",
              value: student?.data.mobileNumber,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Email",
              value: student?.data.email,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Address Line 1",
              value: student?.data.addressLine1,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Address Line 2",
              value: student?.data.addressLine2,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Landmark",
              value: student?.data.landmark,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Town/Village",
              value: student?.data.townOrVillage,
              align: "left",
              minWidth: 170,
            },
            {
              label: "City",
              value: student?.data.city,
              align: "left",
              minWidth: 150,
            },
            {
              label: "State",
              value: student?.data.state,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Country",
              value: student?.data.country,
              align: "left",
              minWidth: 150,
            },
            {
              label: "Pincode/Postcode",
              value: student?.data.pincode,
              align: "left",
              minWidth: 170,
            },
            {
              label: "Course Material Delivery",
              value: materialDeliveryPref ? "Yes" : "No",
              align: "left",
              minWidth: 220,
            },
            {
              label: "Registered On",
              value: timestamp ? timestamp.toDate().toLocaleString("en-US") : new Date('2022-06-03T00:30:00').toLocaleString("en-US"),
              align: "left",
              minWidth: 220,
            }
          ];
        }
      );

      setEnrollments(enrollmentsCombined);
      console.log("Total Enrollments = ", enrollmentsCombined.length);
      console.log("Enrollments = ", enrollmentsCombined);
    } catch (err) {
      console.log("Encountered error while initiliazing enrollments ", err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (loading) {
    return (
      <div
        className="enrollmentManager"
        style={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
        }}
      >
        <CircularProgress size={20} /> Loading Enrollments
      </div>
    );
  }

  return (
    <div className="enrollmentManager">
      <div className="enrollmentManager__header">
        <h1>View Enrollments</h1>
        {enrollments.length > 0 && (
          <DownloadExport
            data={enrollments}
            type="enrollment"
            fileName="enrollments"
          />
        )}
      </div>
      <Divider />
      <p>
        {enrollments.length ? `All (${enrollments.length}) ` : `All `}{" "}
        enrollments can be viewed here
      </p>
      {loading && <CircularProgress size={20} />}
      {!loading && enrollments.length > 0 && (
        <>
          <TableContainer sx={{ maxHeight: 800 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {enrollments[0]?.map((col) => {
                    if (col?.hidden === undefined) {
                      return (
                        <TableCell
                          key={getGuid()}
                          align={col.align}
                          style={{ minWidth: col.minWidth }}
                        >
                          <b>{col.label}</b>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {enrollments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((enrollment) => {
                    return (
                      <TableRow
                        key={getGuid()}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {enrollment.map((cell) => {
                          if (cell?.hidden === undefined) {
                            return (
                              <TableCell key={getGuid()} align={cell.align}>
                                {cell.value ? (
                                  cell.value.toLowerCase().indexOf("deleted") !== -1 ? (
                                    <Chip
                                      label={cell.value}
                                      size="small"
                                      color="error"
                                    />
                                  ) : (
                                    cell.value
                                  )
                                ) : (
                                  <Chip
                                    label="NA"
                                    size="small"
                                    color="warning"
                                  />
                                )}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={enrollments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {!loading && enrollments.length == 0 && (
        <Alert variant="outlined" severity="info">
          No enrollments found!
        </Alert>
      )}
    </div>
  );
}

export default EnrollmentManager;
